$dishtv-font-family: "Roboto", sans-serif;
$font-gillSans: "Gill Sans", sans-serif;

//------------------- DISHTV FONT ------------------

$font-family: $dishtv-font-family;

//--------------------- CHATBOT Variables -------------------
$font-thin: 300;
$font-normal: 400;
$font-medium: 500;
$font-bold: 700;
$font-ultra-bold: 900;

$default-animation-duration: 0.2s;
$default-animation-style: ease-out;

$font-size-xxs: 10px;
$font-size-xs: 12px;
$font-size-sm: 14px;
$font-size-md: 16px;
$font-size-lg: 20px;
$font-size-xl: 24px;
$font-size-xll: 30px;
