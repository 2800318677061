@import "../../data/styles/colors.scss";
@import "../../data/styles/mixins.scss";

.oriTriggerChatBotContainer {
    right: 20px;
    bottom: 20px;
    z-index: 99991;
    cursor: pointer;

    .triggerIconContainer {
        @include create-circle(70px, 70px);
        
        background-color: $primary;
        color: $white;
        box-shadow: 0 5px 4px 0 rgba(0, 0, 0, 0.24);

        &:hover {
            background-color: $primary-light;
        }
    }
}
